import React from 'react'
import { graphql } from 'gatsby'
import { RichText, Date } from 'prismic-reactjs'
import Layout from '../components/layout'
import { ImageCaption, Quote, Text } from '../components/slices'
import { TableOfContents } from '../components/tableofcontents';
import { FAQ } from '../components/FAQ';
// import { RelatedArticles } from '../components/RelatedArticles';
import Author from "../images/blog/me.jpg"
import GatsbyLink from '../components/GatsbyLink'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utils/linkResolver'
import SEO from "../components/seo/seo-new"
// import { LanguageSwitcher } from '../components/LangSwitcher'
// import { motion, useScroll } from "framer-motion";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';


// Query for the Blog Post content in Prismic
export const query = graphql`
  query BlogPostQuery($id: String, $lang: String, $language: String!) {
    prismicPost(id: { eq: $id }, lang: { eq: $lang }) {
      id
      uid
      lang
      alternate_languages {
        id
        type
        uid
        lang
      }
      type
      last_publication_date
      _previewable
      data {
        date_updated
        date_posted
        meta_title
        meta_description
        title {
          richText
          text
        }
        picture {
          url
        }
        author_name {
          text
        }
        description {
          richText
        }
        body {
          ... on PrismicPostDataBodyText {
            slice_label
            slice_type
            primary {
              text {
                richText
              }
              section_anchor {
                text
              }
            }
          }

          ... on PrismicPostDataBodyImageWithCaption {
            id
            slice_label
            slice_type
            primary {
              image {
                alt
                url
              }
              caption {
                richText
              }
            }
          }

          ... on PrismicPostDataBodyFaqSection {
              id
              slice_label
              slice_type
              items {
                text {
                  richText
                  text
                }
                title {
                  text
                }
              }
            }
          ...on PrismicPostDataBodyTableOfContents {
            id
            slice_label
            slice_type
            primary {
              toc {
                text
              }
            }
            items {
              section_anchor {
                text
              }
              section_title {
                text
              }
            }
          }
        }
      }
    }
     locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

// Sort and display the different slice options
const PostSlices = ({ slices }) =>
  slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case 'text':
          return (
            <div id={slice.primary.section_anchor.text} key={index} className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
              <Text slice={slice} />
            </div>
          )

        case 'quote':
          return (
            <blockquote key={index} className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
              <Quote slice={slice} />
            </blockquote>
          )

        case 'image_with_caption':
          return (
            <div key={index} className="mt-6 mb-6">
              <ImageCaption slice={slice} />
            </div>
          )
        case 'table_of_contents':
          return (

            <TableOfContents title={slice.primary.toc.text} items={slice.items} />
          )
        case 'faq_section':
          return (
            <div key={index} >

              <FAQ items={slice.items} />
            </div>
          )

        default:
      }
    })()
    return res
  })


// Display the title, date, and content of the Post
const PostBody = ({ blogPost }) => {

  // Store and format the blog post's publication date
  // let updateDate = Date(blogPost.data.date_updated)
  // updateDate = updateDate
  //   ? new Intl.DateTimeFormat('en-US', {
  //     month: 'short',
  //     day: '2-digit',
  //     year: 'numeric',
  //   }).format(updateDate)
  //   : ''


  const lastPub = blogPost.last_publication_date.substring(0, 10)
  const lastPublished = new Date(lastPub);
    const lastDated = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    }).format(lastPublished)






  return (

    <div>


        <h1 className="block text-xs text-center leading-8 tracking-tight text-white">
        {RichText.asText(blogPost.data.title.richText).length !== 0
          ? RichText.asText(blogPost.data.title.richText)
          : 'Untitled'}
      </h1>
      <div className="mt-6 flex items-center justify-center">
        <div className="flex px-6 text-sm text-gray-500">
          Last Updated: <time className='pl-2'> {lastDated}</time>
        </div>
        <div className="shrink-0">
          <img className="h-6 w-6 rounded-full" src=

            {
              blogPost.data.picture.url !== null
                ? blogPost.data.picture.url
                : Author
            }


            alt={`${blogPost.data.title.richText}`} />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-900">
            {
              blogPost.data.author_name.text.length !== 0
                ? blogPost.data.author_name.text
                : "Michael Schwartz"
            }
          </p>
        </div>


      </div>
      {/* Go through the slices of the post and render the appropiate one */}
      <PostSlices slices={blogPost.data.body} />
    </div>
  )
}


const Post = ({ data, pageContext }) => {
  const lang = data.locales.edges[0].node.language

  if (!data) return null
  // Define the Post content returned from Prismic

  const post = data.prismicPost.data
  const posts = data.prismicPost
  const originalPathname = `/${pageContext.tag}/${pageContext.page}/`



  // const { scrollYProgress } = useScroll()
  return (
    <Layout>


      <SEO
        title={post.meta_title}
        pathname={`${pageContext.page}/`}
        description={post.meta_description}
        node={data}
        image={`/static/${pageContext.seoImage}/`}
        last_publication_date={posts.last_publication_date}
        article
        originalPathname={originalPathname}
        lang={lang}
        alternate
      />

      <div className="relative py-16 bg-white overflow-hidden">
        {/* <>
          <motion.div
            className="progress-bar"
            style={{ scaleX: scrollYProgress }}
          />

        </> */}

        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">

          <div className="relative h-full text-md max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 right-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>


        <div className="relative px-4 sm:px-6">

          <div className="text-lg max-w-prose mx-auto">


            <img
              src={`/static/${pageContext.seoImage}`}
              alt={RichText.asText(post.title.richText).length !== 0
                ? RichText.asText(post.title.richText)
                : 'Untitled'}
            />

            {/* <LanguageSwitcher
              lang={posts.lang}
              altLang={posts.alternate_languages}
            /> */}

            <PostBody blogPost={posts} />


            <div className="flex in-line py-14 text-gray-500 ">
              <div className="shrink-0">
                <img className="h-24 w-24 rounded-full " src=

                  {
                    post.picture.url !== null
                      ? post.picture.url
                      : Author
                  }


                  alt={`${post.title.richText}`} />
              </div>

              <div className="ml-3 block">
                <div className="font-bold text-xl">
                  {
                    post.author_name.text.length !== 0
                      ? post.author_name.text
                      : "Michael Schwartz"
                  }
                </div>

                <div className="text-sm">

                  {
                    RichText.asText(post.description.richText).length !== 0
                      ? <RichText
                        render={post.description.richText || []}
                        serializeHyperlink={GatsbyLink}
                      />

                      : "Michael is the CEO and co-founder of taxomate, one of the leading ecommerce accounting integration software solutions."
                  }


                </div>

                {/* <RelatedArticles
                  uid={posts.uid}
                  // categories={article.data.categories}
                  // tags={article.tags}
                  // related={relatedArticles}
                /> */}



              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default withPrismicPreview(Post, [
  {
    repositoryName: "taxomate-blog",
    linkResolver,
  },
])