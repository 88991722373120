module.exports = {
  // The repo name for your Prismic repository (found in the url of your repo: https://taxomate-blog.prismic.io/)
  prismicRepo: 'taxomate-blog',

  // Release ID to build (requires access)
  releaseID: 'master',

  // access token to access the release
  accessToken: 'MC5ZSTNvVFJFQUFDSUF2U3NF.77-977-9KDVw77-9KGFO77-977-9ZkTvv71Xe3Tvv73vv71YK2Lvv73vv71c77-9ET7vv73vv73vv718',

  // The default language for content in your Prismic repository.
  defaultLanguage: 'en-us',

  // All available languages for content in your Prismic repository.
  langs: ['en-us', 'es-es', 'fr-fr'],
}
