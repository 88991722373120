import React from 'react';

export const TableOfContents = props => {
    const { items } = props;

    return (
        <div className="p-10">
            {items.map((item, i) =>

                <a key={item.section_anchor.text} href={`#${item.section_anchor.text}`}>
                    <div className="text-md mt-2 flex align-left">
                        <div className="rounded-full text-white bg-blue-600 h-7 w-7 p-3 flex items-center justify-center">
                            {i + 1}
                        </div>
                        <div className="pl-2 text-blue-600 hover:text-blue-900">
                            {item.section_title.text}
                        </div>
                    </div>
                </a>
            )}

        </div>
    );
};
