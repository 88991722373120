import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import SEO from "../components/seo/seo-new"
import Layout from "../components/layout";
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import PageTemplate from '../templates/post'


import { linkResolver } from '../utils/linkResolver'


function NotFoundPage() {
  return (

    <Layout>
      <SEO
        title="404: Not found"
        description="404 taxomate"
        image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
        pathname="/404/"
        originalPathname="/404/"
      />
      <div className="flex flex-wrap">
          <div className="inline-block flex-row md:flex-row flex-wrap md:w-2/5 pt-14 pr-0 pl-6 px-6 text-gray-700">
              <div className="inline-flex flex-wrap max-w-md justify-start items-start">
                  <div className="font-dark font-bold text-5xl h-12">404</div>
                    <p
                      className="font-light text-2xl md:text-3xl leading-normal mt-6"
                    >Sorry we couldn't find this page. </p>
                  <p className="h-12 mb-8 mt-6">But dont worry, you can find plenty of other things on our homepage.</p>
                  </div>

             <a href="/" className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700">back to homepage</a>

          </div>
          <div className="md:w-3/5 inline-flex justify-start">
              <StaticImage src="../images/missing.png" alt="404" className="inline-flex justify-start items-center animate-pulse" />
            </div>
        </div>

    </Layout>
  );
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryname: "taxomate-blog",
    linkResolver,
    componentResolver: componentResolverFromMap({
      page: PageTemplate,
    }),
  },
])