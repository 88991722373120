import React from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from './GatsbyLink'

export const FAQ = props => {
    const { items } = props;

    return (
        <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <h2>
                Frequently Asked Questions
            </h2>

            {items.map((item, i) =>

                <div key={item.title.text}>

                    <h3 className='italic'>
                        {item.title.text}
                    </h3>

                    <RichText
                        render={item.text.richText || []}
                        serializeHyperlink={GatsbyLink}
                    />

                </div>
            )}

        </div>
    );
};
