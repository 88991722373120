const { defaultLanguage } = require('../../prismic-configuration')

const langs = {
  'fr-fr': 'fr',
  'es-es': 'es'
}

const linkResolver = (doc) => {

  if (doc.type === 'post') {

    return doc.lang === defaultLanguage
      ? `/${doc.tags[0]}/${doc.uid}`
      : `/${langs[doc.lang]}/${doc.tags[0]}/${doc.uid}`


    // if (doc.tags?.includes('amazon-seller-blog')) {
    //   return `/amazon-seller-blog/${doc.uid}`
    // }

    // if (doc.tags?.includes('amazon-accounting-blog')) {
    //   return `/amazon-accounting-blog/${doc.uid}`
    // }

  }
  return '/'
}

module.exports = linkResolver